// sendChatStart.js
'use strict';
import fetch from '../resource/customFetch.js';
import { getHeaders } from '../resource/fetchOptionHeader.js';
import getResourceUrl from '../resource/getResourceUrl.js';
import handleFetchError from '../resource/handleFetchError.js';
import getMeData from '../selector/getMeData.js';
import { push, replace } from '../action/navigationAction.js';
import postMessageToSwagApp from '../action/postMessageToSwagApp.js';
import getSwagrUniversalLink from '../resource/getSwagrUniversalLink.js';
import getSchatUniversalLink from '../resource/getSchatUniversalLink.js';
import getRouterData from '../selector/getRouterData.js';

import {
  MERGE_OPERATION_DATA,
  SET_NETWORKING_FETCHING,
  SET_NETWORKING_SUCCESS,
  SET_NETWORKING_ERROR,
} from '../ActionTypes.js';

/**
 * Send chat start
 * @kind action
 * @param {string} { userId } - user id.
 * @param {('browserHistoryPush' | 'schatInAppWebView' | 'swagrInAppWebView')} { [redirectMethod] } handle route redirect method
 * @return {Promise} Action promise.
 */

const sendChatStart =
  ({ userId, redirectMethod = 'browserHistoryPush' }) =>
  async (dispatch, getState) => {
    const token = getMeData(getState(), 'token');

    if (!token) {
      return dispatch(push(`/chat/user/${userId}`));
    }

    const fetchOptions = {
      method: 'HEAD',
      headers: {
        ...getHeaders(),
        Authorization: `Bearer ${token}`,
      },
    };

    const selectPath = ['chat', userId];
    const url = getResourceUrl({ endpoint: `/user/${userId}/chat` });

    dispatch({ type: SET_NETWORKING_FETCHING, payload: { selectPath } });

    try {
      let response = await fetch(url.href, fetchOptions);

      if (!response.ok) {
        response = await handleFetchError({
          response,
          dispatch,
          getState,
          fetchOptions,
          fetchUrl: url,
        });
      }

      const location = response.headers.get('Location');
      const chatroomId = location.split('/').slice(-1)[0];

      dispatch({ type: SET_NETWORKING_SUCCESS, payload: { selectPath } });
      dispatch({
        type: MERGE_OPERATION_DATA,
        payload: {
          selectPath: ['userChatroomId'],
          data: { [userId]: chatroomId },
        },
      });

      const direction = {
        view: 'chatroom',
        'chatroom-id': chatroomId,
      };

      const from = getRouterData(getState(), ['location']);
      switch (redirectMethod) {
        case 'schatInAppWebView':
          return dispatch(
            postMessageToSwagApp({
              message: getSchatUniversalLink({
                search: direction,
              }),
            })
          );
        case 'swagrInAppWebView':
          return dispatch(
            postMessageToSwagApp({
              message: getSwagrUniversalLink({
                search: direction,
              }),
            })
          );
        case 'browserHistoryReplace':
        case 'browserHistoryPush':
        default: {
          const path = `/chat/${chatroomId}${url.search}`;
          if ('browserHistoryReplace' === redirectMethod) {
            return dispatch(
              replace(path, {
                from,
              })
            );
          }
          return dispatch(push(path, { from }));
        }
      }
    } catch (error) {
      return dispatch({
        type: SET_NETWORKING_ERROR,
        payload: { selectPath, error },
      });
    }
  };

export default sendChatStart;
