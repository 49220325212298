// CategoryIcon.js
'use strict';
import { connect } from 'react-redux';

import CategoryIcon from '../component/CategoryIcon.jsx';
import getRemoteConfigData from '../selector/getRemoteConfigData.js';
import { PUBLIC_BADGE_URL_PATH } from '../RemoteConfigKeys.js';

const mapStateToProps = state => {
  const publicBadgeUrlPath = getRemoteConfigData(state, PUBLIC_BADGE_URL_PATH);
  return {
    publicBadgeUrlPath,
  };
};

export default connect(mapStateToProps, null)(CategoryIcon);
