// getSwagrUniversalLink.js
'use strict';

// TODO: remote config (start)
const swagUniversalDomain = 'go.swag.live';
const swagrUniversalDomain = 'go.swagr.io';
const swagStoreId = '1491273813';
const swagrStoreId = '1453141749';
const swagrBundleName = 'com.swagr.io';
const swagBundleName = 'com.swaglive.swag';
// TODO: remote config (end)

import {
  getBundleNameInWebview,
  getIsOnApple,
} from '../resource/getUserAgent.js';

/**
 * get Swagr universal link
 *
 * @param {object}  options            - required, options
 * @param {string}  options.path       - optional, path is used for redirect Swagr app
 * @param {object}  options.search     - optional, search is used for link's query string
 * @param {string}  options.fallback   - optional, fallback url, will be same as path by default
 * @param {boolean} options.toAppStore - optional, redirect to app store or google paly
 *
 * @return {string} Swagr universal link
 */
const getSwagrUniversalLink = ({
  path = '/',
  search,
  fallback = path,
  toAppStore = false,
}) => {
  const appBundleName = getBundleNameInWebview();
  const domain = getUniversalLinkDomain(appBundleName);
  const { protocol, origin } = location;
  const fl = new URL(`${origin}${fallback}`);
  const url = new URL(`${protocol}//${domain}`);
  const link = new URL(`${origin}${path}`);
  if (search != null) {
    Object.keys(search).forEach(key => {
      link.searchParams.set(key, search[key]);
    });
  }
  url.searchParams.set('link', link.href);
  url.searchParams.set('efr', '1'); // skip middle page for firebase
  url.searchParams.set('ibi', appBundleName);
  url.searchParams.set('apn', appBundleName);
  url.searchParams.set('ofl', fl.href);

  if (toAppStore && getIsOnApple()) {
    const storeId = getAppStoreId(appBundleName);
    url.searchParams.set('isi', storeId);
  } else {
    url.searchParams.set('ifl', fl.href);
    url.searchParams.set('afl', fl.href);
  }
  return url.href;
};

const getUniversalLinkDomain = appBundleName => {
  switch (appBundleName) {
    case swagrBundleName:
      return swagrUniversalDomain;
    case swagBundleName:
    default:
      return swagUniversalDomain;
  }
};

const getAppStoreId = appBundleName => {
  switch (appBundleName) {
    case swagrBundleName:
      return swagrStoreId;
    case swagBundleName:
    default:
      return swagStoreId;
  }
};
export default getSwagrUniversalLink;
