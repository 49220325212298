// getSchatUniversalLink.js
'use strict';

const isServer = typeof window === 'undefined';

/**
 * get schat universal link
 *
 * @param {object}  options            - required, options
 * @param {string}  options.path       - optional, path is used for redirect schat app
 * @param {object}  options.search     - optional, search is used for link's query string
 * @param {string}  options.fallback   - optional, fallback url, will be same as path by default
 * @param {boolean} options.toAppStore - optional, redirect to app store or google paly
 *
 * @param {object}  configs            - optional, configs from remote server
 *
 * @return {string} schat universal link
 */
const getSchatUniversalLink = ({
  path = '/',
  search,
  fallback = path,
  toAppStore = false,
  remoteConfigs = {},
}) => {
  const {
    remoteConfigSchatIosStoreId,
    remoteConfigSchatIosBundleId,
    remoteConfigSchatUniversalDomain,
    remoteConfigSwagAndroidPackageName,
  } = remoteConfigs;

  if (!remoteConfigSchatUniversalDomain) return '';

  const protocol = isServer ? 'https:' : location.protocol;
  const origin = isServer ? `${protocol}//swag.live` : location.origin;

  const fl = new URL(`${origin}${fallback}`);
  const url = new URL(`${protocol}//${remoteConfigSchatUniversalDomain}`);
  const link = new URL(`${origin}${path}`);
  if (search != null) {
    Object.keys(search).forEach(key => {
      if (Array.isArray(search[key])) {
        const searchArray = search[key];
        searchArray.forEach(id => {
          link.searchParams.append('id', id);
        });
      } else {
        link.searchParams.set(key, search[key]);
      }
    });
  }
  url.searchParams.set('link', link.href);
  url.searchParams.set('efr', '1'); // skip middle page for firebase
  url.searchParams.set('ibi', remoteConfigSchatIosBundleId);
  url.searchParams.set('apn', remoteConfigSwagAndroidPackageName);
  url.searchParams.set('ofl', fl.href);
  if (toAppStore) {
    url.searchParams.set('isi', remoteConfigSchatIosStoreId);
  } else {
    url.searchParams.set('ifl', fl.href);
    url.searchParams.set('afl', fl.href);
  }
  return url.href;
};

export const openSchatUniversalLink = ({
  path = '/',
  search,
  fallback = path,
  toAppStore = false,
  remoteConfigs = {},
}) => {
  const link = getSchatUniversalLink({
    path,
    search,
    fallback,
    toAppStore,
    remoteConfigs,
  });
  return location.assign(link);
};

export default getSchatUniversalLink;
