// CategoryIcon.jsx
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';

import DecryptionWrapper from '../container/DecryptionWrapper.js';

import StatefulImage from '../component/StatefulImage.jsx';

import { getHashColor } from '../resource/getHashColor.js';
import getResourceUrl from '../resource/getResourceUrl.js';
import { PUBLIC } from '../resource/resourceUrlTypeConstants.js';
import { TranslationNamespace } from '../resource/translationNamespace.js';
import getTranslationKey from '../resource/getTranslationKey.js';

import HashtagIconSrc from '../../img/ic_hashtag_16_x_16.svg';

export const CategoryIcon = ({
  category,
  defaultIconSource,
  publicBadgeUrlPath,
  iconExtension,
  alt,
  size,
  t,
}) => {
  const hashColor = useMemo(() => {
    return getHashColor({ hashStr: category });
  }, [category]);
  const badgeIconSource = useMemo(() => {
    return getResourceUrl({
      path: publicBadgeUrlPath,
      endpoint: `/${category}.${iconExtension}`,
      resourceType: PUBLIC,
    }).href;
  }, [category, iconExtension, publicBadgeUrlPath]);
  return (
    <StyledCategoryIcon size={size}>
      <StatefulImage defaultLoadState="error">
        <Icon
          width={size}
          height={size}
          data-key="loading"
          src={defaultIconSource}
          isDefault
          css={`
            background-color: ${hashColor};
            padding: 2px;
          `}
        />
        <Icon
          width={size}
          height={size}
          data-key="error"
          src={defaultIconSource}
          isDefault
          css={`
            background-color: ${hashColor};
            padding: 2px;
          `}
        />
        <DecryptionWrapper resourceUrl={badgeIconSource} data-key="target">
          <Icon
            src={badgeIconSource}
            width={size}
            height={size}
            alt={
              alt ||
              t(getTranslationKey({ key: category }), {
                ns: TranslationNamespace.FEED,
              })
            }
          />
        </DecryptionWrapper>
      </StatefulImage>
    </StyledCategoryIcon>
  );
};

CategoryIcon.propTypes = {
  category: PropTypes.string,
  defaultIconSource: PropTypes.string,
  iconExtension: PropTypes.string,
  publicBadgeUrlPath: PropTypes.string,
  alt: PropTypes.string,
  size: PropTypes.number,
  t: PropTypes.func.isRequired,
};

CategoryIcon.defaultProps = {
  category: '',
  defaultIconSource: HashtagIconSrc,
  iconExtension: 'png',
  alt: '',
  publicBadgeUrlPath: '',
  size: 20,
};

const StyledCategoryIcon = styled.div.attrs(({ size }) => ({
  style: {
    width: `${size}px`,
    height: `${size}px`,
  },
}))`
  position: relative;
`;

const Icon = styled.img.attrs(({ width, height }) => ({
  style: {
    width: `${width}px`,
    height: `${height}px`,
  },
}))`
  border-radius: 50%;
  object-fit: contain;
`;

export default withTranslation()(CategoryIcon);
